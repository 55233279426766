<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12" class="mr-7 ml-5" style="width: 97%">
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers2"
                  :items="images"
                  sort-by="id"
                  class="elevation-1"
                  style="background: #f5f5f5"
                  :server-items-length="totalItems1"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar flat elevation="1" style="background: #f5f5f5">
                      <v-row>
                        <v-col cols="4" align-self="center">
                          <v-toolbar-title class="mb-0 font-weight-bold"
                            >Banner Image</v-toolbar-title
                          >
                        </v-col>
                        <v-col
                          cols="4"
                          class="d-flex fle-row justify-space-around align-center"
                        >
                          <v-toolbar-title
                            >Total Images: {{ total_images }}</v-toolbar-title
                          >
                          <v-toolbar-title> Time: {{ time }} </v-toolbar-title>
                        </v-col>
                        <v-col cols="4">
                          <v-btn
                            color="primary "
                            dark
                            to="/dashboard/pages/addbanner"
                            class="float-right"
                            elevation="0"
                          >
                            <v-icon color="white">mdi-plus</v-icon>Add Banner
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-dialog v-model="dialog1" max-width="500px">
                        <v-card>
                          <v-card-title>
                            <span class="headline"
                              >Edit Banner Information</span
                            >
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editedItem.set_time"
                                    label="Set Time in millisecond "
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <template>
                                    <v-file-input
                                      v-model="image"
                                      color="deep-purple accent-4"
                                      counter
                                      label="Upload Your Image"
                                      multiple
                                      outlined
                                      :show-size="1000"
                                    >
                                      <template
                                        v-slot:selection="{ index, text }"
                                      >
                                        <v-chip
                                          v-if="index < 2"
                                          color="deep-purple accent-4"
                                          dark
                                          label
                                          small
                                          >{{ text }}</v-chip
                                        >

                                        <span
                                          v-else-if="index === 2"
                                          class="overline grey--text text--darken-3 mx-2"
                                          >+{{ files.length - 2 }} File(s)</span
                                        >
                                      </template>
                                    </v-file-input>
                                  </template>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="close"
                              >Cancel</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="saveEdit"
                              >Save</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <!-- <v-icon small class="mr-2" @click="editItem(item)"
                      >mdi-pencil</v-icon
                    > -->
                    <v-btn text small ripple color="red" v-if="item.is_active" @click="action(item)">Make Inactive</v-btn>
                    <v-btn text small ripple v-else @click="action(item)">Make Active</v-btn>
                  </template>
                </v-data-table>

                  <div class="d-flex justify-content-center mt-5">
                    <!-- <v-col cols="3" md="3"> -->
                    <div class="col-4">
                        Total {{ totalItems1 }} records
                    </div>
                    <!-- </v-col> -->
                    <!-- <v-col cols="3" md="3"> -->
                    <div class="col-4">
                        <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                        </v-select>                                                
                    </div>
                    <!-- </v-col> -->
                    <!-- <v-col cols="6" md="3" > -->
                    <div class="col-4">
                        <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                        </v-pagination>
                    </div>
                    <!-- </v-col> -->
                  </div>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog1: false,
    dialog2: false,
    set_time: "",
    link: "",
    content: "",
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    images: "",
    time: "",
    total_images: "",
    contact: {},
    banner_id: "",

    headers2: [
      {
        text: "Image ID",
        align: "start",
        sortable: false,
        value: "Banner_id",
      },
      { text: "Link", value: "image_link" },
      { text: "Content", value: "content",align: "center" },
      { text: "Show", value: "is_active" , align: "center",},
      { text: "Action", value: "actions", sortable: false , align: "center",},
    ],
    banners: [],
    images: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      set_time: "",
      image: [],
    },
    defaultItem: {
      set_time: "",
      image: [],
    },
    editedIndex2: -1,
    editedBanner: {
      Banner_id: "",
    },
    defaultItem2: {
      Banner_id: "",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog1(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
  },
  methods: {
    handlePageSizeChange(event) {
      this.perPage1 = (event);
      this.loading = true;
      this.initialize();
    },

    handlePageChange(value) {
      this.currentPage1 = value;
      this.loading = true;
      this.initialize();
      window.scrollTo(0, 0);
    },

    initialize() {
      let perPage ;
      if (this.perPage1 === 'All'){
        perPage = this.totalItems1;
      }
      else
      {
        perPage = this.perPage1;
      }
      this.loading = true;

      axios.get(`site/admin_banner/?page=${this.currentPage1}&size=${perPage}`).then((response) => {
        this.images = response.data.results;
        this.totalItems1 = response.data.count;
        this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
        this.total_images = response.data.images.length;
        this.time = response.data.banner_data[0].set_time;
        this.banner_id = response.data.images[0].Banner_id;
        this.loading = false;
      });
    },

    editItem2(item2) {
      this.editedIndex = this.images.indexOf(item2);
      this.editedImage = Object.assign({}, item2);
      this.dialog2 = true;
    },

    action(item) {
      const index = this.images.indexOf(item);
      axios
        .post(`site/banner_image_status/${item.id}/`)
        .then((response) => {
          Object.assign(this.images[index], response.data.data);
        });
    },

    deleteItem2(item2) {
      const index = this.images.indexOf(item2);

      confirm("Are you sure you want to delete this item?") &&
        this.images.splice(index, 1);
    },

    close() {
      this.dialog1 = false;
      this.dialog2 = false;
      this.dialog3 = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.dialog2 = false;
        this.editedItem2 = Object.assign({}, this.defaultItem);
        this.editedIndex2 = -1;
      });
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog1 = true;
    },

    saveEdit() {
      axios
        .post(`site/banner_img_update/${this.editedItem.id}/`, this.editedItem)
        .then((response) => {
          this.dialog1 = false;
        });
    },

    confirm() {
      if (this.editedIndex2 > -1) {
        Object.assign(this.images[this.editedIndex2], this.editedItem2);
      } else {
        this.images.push(this.editedItem2);
      }
      this.editedImage.link = "";
      this.editedImage.content = "";
      this.editedImage.image = "";
      this.editedImage.Banner_id = "";
      this.close();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>

